<template>
  <div class="bg cl">
    <div class="mylist">
      <div class="listtop">
        <div class="cl">
          <img src="https://img.yzcdn.cn/vant/apple-1.jpg" class="fl" alt="" />
          <div class="fl">
            <p>王林 主任医生</p>
            <p class="em">中国人民解放军总医院301医院</p>
            <p class="em">呼吸科</p>
          </div>
        </div>
      </div>
    </div>
    <div class="infobox">
      <h3>擅长领域</h3>
      <div class="describe" :class="{ sl: !unfold }">
        慢性咳嗽、哮喘、支气管炎、阻塞性睡眠呼吸暂停低通气综合征（鼾症）的定位诊断及微创手术，经支撑喉镜内镜下会,阻塞性睡眠呼吸的定位诊断及微创手术，阻塞性睡眠呼吸的定位诊断及微创手术，阻塞性睡眠呼吸的定位诊断及微创手术，阻塞性睡眠呼吸的定位诊断及微创手术，
      </div>
      <div>
        <span class="unfold" @click="unfold = !unfold" v-if="!unfold"
          >展开全部 <img src="../../assets/img/xia.png" alt=""
        /></span>
        <span class="unfold" @click="unfold = !unfold" v-else
          >收起全部 <img src="../../assets/img/shang.png" alt=""
        /></span>
      </div>
      <van-button type="primary" block class="btn" color=" #1daefc"
        >私人健康咨询</van-button
      >
    </div>
    <div class="infolist">
      <h3>擅长领域</h3>
      <ul>
        <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
        <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
        <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
        <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
        <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
        <li>华中科技科技大写附属同济医院光谷区/内科/呼吸科/内科/呼吸科</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: "0",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      unfold: false,
    };
  },
  mounted() {},
  methods: {
    // 问诊页面
    goinquirying() {
      this.$router.push({ name: "inquirying" });
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>
<style scoped>
.bg {
  background: #f5f5f5;
}
/deep/.mytab .van-tab--active .van-tab__text {
  color: rgba(255, 255, 255, 1);
}
/deep/.mytab .van-tab--active .van-tab {
  border: none;
  box-sizing: border-box;
}

/deep/.mytab .van-tab {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
}
/deep/.mytab .van-tab__text {
  z-index: 10;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
}
/deep/.mytab .van-tabs__line {
  width: 50%;
  height: 100%;
  background: #1daefc;
}
</style>
<style lang="less" scoped>
.infolist {
  padding: 16px;
  background: #fff;

  h3 {
    text-align: left;
    margin-bottom: 16px;
  }
  ul {
    li {
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      // padding: 15px 0;
      line-height: 49px;
      color: #2d2d2d;
      font-size: 14px;
    }
  }
}
.btn {
  width: 250px;
  height: 44px;
  // background: #1daefc;
  border-radius: 10px;
  margin: 25px auto;
}
.infobox {
  margin-top: 8px;
  padding: 16px;
  background: #fff;
  margin-bottom: 8px;
  h3 {
    text-align: left;
    margin-bottom: 16px;
  }
  .describe {
    text-align: left;
    // font-weight: 700;
    line-height: 18px;
    color: #2d2d2d;
    margin-bottom: 16px;
  }
  .unfold {
    color: #1daefc;
    font-size: 12px;
    img {
      width: 11px;
      height: 6px;
      margin-left: 8px;
    }
  }
}
.mylist {
  .listbot {
    font-size: 14px;
    text-align: left;
    margin-top: 15px;
  }
  .top_right {
    .em {
      margin-right: 15px;
    }
    .zt {
      font-size: 14px;
      color: rgba(29, 174, 252, 1);
    }
    .zt.on {
      font-size: 14px;
      color: #d8d8d8;
    }
  }
  width: 100%;
  background: #ffffff;
  // height: 154px;
  // margin-bottom: 10px;
  // border-bottom: 1px solid #EFEFEF;
  box-sizing: border-box;
  padding: 16px;
  .listtop {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    p {
      text-align: left;
      margin-bottom: 5px;
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
.mytab {
  width: 375px;
  height: 48px;
  box-sizing: border-box;
  .van-tab--active {
    .van-tab__text {
      color: rgba(255, 255, 255, 1);
    }
    .van-tab {
      border: none;
      box-sizing: border-box;
    }
  }
  .van-tab {
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
  }
  .van-tab__text {
    z-index: 10;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
  }
  .van-tabs__line {
    width: 50%;
    height: 100%;
    background: #1daefc;
  }
}
</style>
